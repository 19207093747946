export const environment = {
    production: false,
    baseUrl: 'http://34.73.3.99/1.0',
    claimApiUrl: 'https://nonprod.claim.lhc.lmig.com',
    policyApiUrl: 'https://nonprod.policy.lhc.lmig.com',
    toeknApiUrl: 'https://nonprod.authen.lhc.lmig.com',
    currencyApi: 'https://nonprod.master.lhc.lmig.com',
    termsUrl: 'https://www.libertyinsurance.com.hk/en/terms-and-conditions/',
    privacyUrl: 'https://www.libertyinsurance.com.hk/en/privacy-policy/',
    mobilePrivacyUrl: 'https://www.libertyinsurance.com.hk/en/mobile-app-policy/',
    easyClaimUrl: 'https://www.libertyinsurance.com.hk/',
    contactUsUrl: 'https://www.libertyinsurance.com.hk/LHC/contactUs.jsp?lang=0',
    appPlayStoreUrl: 'https://play.google.com/store/apps/details?id=com.libertyhk.eClaim&hl=en',
    appAppleUrl: 'https://apps.apple.com/hk/app/libertyhk-liberty-health-club/id1066669506?l=en',
    pdfFormUrl: 'https://www.libertyinsurance.com.hk/sites/libertyhk/files/documents/claims_hk/cf_outpatient.pdf',
    terms_and_conditionUrl: 'https://ww02uat.libertymutual.com.hk/Pages/ECSD_0001.htm',
    acceptableUrl: 'https://uat.libertyinsurance.com.hk/acceptable-use-policy',
    cookieUrl: 'https://uat.libertyinsurance.com.hk/cookie-policy',
    endUserUrl : 'https://uat.libertyinsurance.com.hk/end-user-application-policy',
    personalUrl: 'https://uat.libertyinsurance.com.hk/personal-information-collection-statement',
    premiumUrl: 'https://uat.libertyinsurance.com.hk/premium-levy',
    privacyLinkUrl: 'https://uat.libertyinsurance.com.hk/privacy-policy',
    securityUrl: 'https://uat.libertyinsurance.com.hk/security-policy',
    tncUrl: 'https://uat.libertyinsurance.com.hk/terms-and-conditions',
    googleAnalyticsKey: 'UA-145594648-2'
};
